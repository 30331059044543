/* eslint-disable react-hooks/exhaustive-deps */
import { Radio, Card, Input, Button, Divider, Space, Select } from 'antd';
import { months } from 'src/utils/calendar-info';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Balancer } from 'react-wrap-balancer';
import api from 'src/api';
import d from 'dayjs';
import Spinner from './spinner';
import { useCalendarEntries } from 'src/hooks/calendar-entries.queries';
import { useEffect, useState } from 'react';

// const iconStyles = {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   height: '30px',
//   width: '30px',
//   borderRadius: '100vw',
//   backgroundColor: '#f1f1f1'
// };

function ManageHotelDayCard({
  selectedDate,
  month,
  year,
  setUpdatingCalendar,
  room,
  roomId
}) {
  const [searchParams] = useSearchParams();
  const propertyId = searchParams.get('propertyId');
  const queryClient = useQueryClient();
  const from = d()
    .set('date', selectedDate.date)
    .set('month', month)
    .set('year', year);

  const [text, setText] = useState('');

  const { isFetching, calendarEntries } = useCalendarEntries({
    from,
    propertyId,
    roomId
  });
  const [count, setCount] = useState(0);

  const [entry, setEntry] = useState({ isBlocked: false });
  const entryExists = !!entry._id;

  const { mutate, status } = useMutation({
    mutationFn: async () => {
      setUpdatingCalendar(true);
      const isBlocked = entry.isBlocked;

      const promises = selectedDate.map(async date => {
        const formattedDate = d()
          .set('year', year)
          .set('month', month)
          .set('date', date)
          .format('YYYY-MM-DD');

        try {
          if (entryExists && !isBlocked && !count) {
            return await api.delete(`/calendar/${entry._id}`);
          } else if (entryExists) {
            return await api.put('/calendar', {
              calendarEntryId: entry._id,
              roomCount: count
            });
          } else {
            return await api.post('/calendar', {
              propertyId,
              date: formattedDate,
              isBlocked: count === room.count,
              roomTypeId: roomId,
              roomCount: count,
              ...(text && { reason: text })
            });
          }
        } catch (error) {
          console.error(`Error updating date ${formattedDate}:`, error);
          throw error;
        }
      });

      await Promise.all(promises);

      selectedDate.forEach(date => {
        const dateKey = d()
          .set('date', date)
          .set('month', month + 1)
          .set('year', year)
          .format('YYYY-MM-DD');

        queryClient.invalidateQueries(
          ['calendar-entries', propertyId, dateKey],
          { exact: true }
        );
      });
    },
    onSuccess: () => {
      setUpdatingCalendar(false);
    }
  });

  useEffect(() => {
    if (calendarEntries.length) {
      const isFullyBlock = calendarEntries[0].roomCount === room.count;
      setEntry({ ...calendarEntries[0], isBlocked: isFullyBlock });
      setCount(calendarEntries[0].roomCount);
      setText(calendarEntries[0].reason);
    } else {
      setCount(room.count);
      setEntry({ isBlocked: false });
      setText('');
    }
  }, [calendarEntries?.[0], selectedDate]);

  return (
    <>
      {room ? (
        <div
          style={{
            minHeight: '500px',
            width: '100%',
            border: '1px solid #8d9197',
            background: '#fff',
            borderRadius: '10px',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#f1f1f173',
              padding: '10px',
              borderRadius: '10px'
            }}
          >
            <p style={{ fontWeight: '500' }}>
              {selectedDate.date} {months[month]} {year}
            </p>
            {/* {entry.bookingId && (
          <p
            style={{
              color: '#2a9d8f',
              background: '#e9f7f6',
              width: 'fit-content',
              padding: '5px 20px',
              borderRadius: '100vw',
              fontWeight: 600
            }}
          >
            Booked
          </p>
        )} */}
            {entry?.isBlocked && (
              <p
                style={{
                  color: '#f02929',
                  background: '#f7e9e9',
                  width: 'fit-content',
                  padding: '5px 20px',
                  borderRadius: '100vw',
                  fontWeight: 600
                }}
              >
                Blocked
              </p>
            )}
          </div>
          <h4>
            <Balancer>Open and Close for Bookings</Balancer>
          </h4>
          <div>
            {status === 'pending' || isFetching ? (
              <Spinner />
            ) : (
              <Radio.Group
                value={entry?.isBlocked ? 'blocked' : 'open'}
                onChange={e => {
                  const isBlocked = e.target.value === 'blocked';
                  setEntry(prev => ({ ...prev, isBlocked }));
                }}
              >
                <Radio value={'open'}>Open</Radio>
                <Radio value={'blocked'}>Close</Radio>
              </Radio.Group>
            )}
          </div>
          <Divider style={{ marginBlock: '0.5rem' }} />
          <Space style={{ width: '100%' }} direction="vertical">
            <label>Select amount to sell</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={count}
              onChange={value => {
                setCount(value);
              }}
              disabled={entry.isBlocked}
              options={Array.from({ length: room.count + 1 }).map((_, i) => ({
                value: room.count - i,
                label: `${i} options to sell`
              }))}
            />
          </Space>
          {/* <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          justifyContent: 'center'
        }}
      >
        {!entry?.isBlocked &&
          allRooms.map(room => (
            <Card key={room} style={{ width: '100%' }}>
              <h3 style={{ color: 'var(--main-brand-color)' }}>
                {rooms[room].roomName}
              </h3>
              <div style={stats}>
                <p>Booked</p>
                <p>{lookup[room]?.roomCount || 0}</p>
                <p>Available</p>
                <p>{rooms[room].count - (lookup[room]?.roomCount || 0)}</p>
              </div>
            </Card>
          ))}
      </div> */}
          {entry.isBlocked && (
            <Input.TextArea
              rows={4}
              value={text}
              onChange={e => setText(e.target.value)}
            />
          )}
          <Button
            type="primary"
            onClick={mutate}
            disabled={!entry.isBlocked && (entry?.roomCount || 0) === count}
          >
            Save
          </Button>
        </div>
      ) : null}
    </>
  );
}

export default ManageHotelDayCard;
